import React from "react";
import pt from "prop-types";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import AppButton from "../global/elements/AppButton";
import TextFieldInput from "../global/FormInputComponents/TextFieldInput";
import { flexColumn } from "../../lib/styleConstants";

const styles = {
  form: {
    rowGap: "1em",
  },
};
const PasswordResetSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Email is Required"),
});

const ResetPasswordForm = ({ resetUserPassCall }) => {
  return (
    <Formik
      initialValues={{
        email: "",
      }}
      validationSchema={PasswordResetSchema}
      onSubmit={(values, { setSubmitting, setFieldError }) => {
        return new Promise(() => {
          resetUserPassCall(values, setSubmitting, setFieldError);
        });
      }}
    >
      {({ setFieldValue }) => {
        return (
          <Form
            id="passwordResetForm"
            style={{ ...flexColumn, ...styles.form }}
          >
            <TextFieldInput
              name="email"
              label="Email"
              type="email"
              setFieldValue={setFieldValue}
            />
            <AppButton
              type="submit"
              form="passwordResetForm"
              look="black"
              label="Send reset link"
              addtionalStyle={{ width: "100%" }}
            />
          </Form>
        );
      }}
    </Formik>
  );
};

ResetPasswordForm.propTypes = {
  resetUserPassCall: pt.func.isRequired,
};

export default ResetPasswordForm;
