import React from "react";
import { Box, Avatar } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import theme from "../../../theme";

const styles = {
  avatar: {
    backgroundColor: "white !important",
    border: `3px solid ${theme.palette.black.coolGrey}`,
    cursor: "pointer",
  },
  ArrowBackIconStyle: {
    color: theme.palette.black.coolGrey,
    fontSize: "2rem",
  },
};

const BackIcon = () => {
  return (
    <Box onClick={() => window.history.back()}>
      <Box sx={{ display: "flex" }}>
        <Avatar sx={styles.avatar}>
          <ArrowBackIcon sx={styles.ArrowBackIconStyle} />
        </Avatar>
      </Box>
    </Box>
  );
};

export default BackIcon;
