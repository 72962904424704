import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import ResetPasswordForm from "../../components/reset-password/form";
import { handleUserPasswordReset } from "../../services/auth";
import firebase from "src/firebase";
import AppButton from "../../components/global/elements/AppButton";
import theme from "../../theme";
import SignInFormLayout from "../../components/forms/SignInUp/components/SignInFormLayout";
import SignInFormHeader from "../../components/forms/SignInUp/components/SignInFormHeader";
import { formContentDiv } from "../../lib/styleConstants";
import { userRoles } from "../../lib/constants";
import FormErrors from "../../components/global/forms/FormErrors";
import { formErrorsAlert } from "../../lib/styleConstants";

const styles = {
  innerContainer: {
    padding: "2em",
  },
  backButton: {
    marginTop: "1em",
    width: "100%",
  },
  backArrow: { color: theme.palette.black.darkSeaBlack },
  formContainer: {
    marginTop: "40px",
  },
};
const ResetPasswordPage = () => {
  const [hasError, showErrorMessage] = useState(false);
  const [errorMessageLabel, setErrorMessageLabel] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [userRole, setUserRole] = useState("");
  const [loginLink, setLoginLink] = useState("/crowddroning/client/sign-in");

  useEffect(() => {
    const role = window?.history?.state?.role || null;
    setUserRole(role);
    if (role == userRoles.pilot) {
      setLoginLink("/crowddroning/pilot/sign-in");
    } else if (role == userRoles.client) {
      setLoginLink("/crowddroning/client/sign-in");
    }
  }, []);
  const isClient = userRole === userRoles.client;

  const resetUserPass = (values, setSubmitting) => {
    const resetPass = handleUserPasswordReset({
      firebase,
      email: values.email,
    });

    resetPass.catch((error) => {
      if (error.code === "auth/user-not-found") {
        showErrorMessage(true);
        setErrorMessageLabel("Email not found");
        setErrorMessage("The email could not be found.");
      }

      // Enable submit button on error
      setSubmitting(false);
    });
  };
  return (
    <SignInFormLayout isClient={isClient}>
      <Box sx={formContentDiv}>
        <SignInFormHeader
          title="Forgot your password?"
          subtitle="No problem, we got you covered. You can reset it here"
          user={userRole}
        />
        <Box sx={styles.formContainer}>
          <ResetPasswordForm resetUserPassCall={resetUserPass} />
          <AppButton
            look="ghost"
            noIcon
            label="Back to Sign In"
            addtionalStyle={styles.backButton}
            url={loginLink}
            internalLink
          />
          <Box sx={{ padding: "0 1em" }}>
            {hasError ? (
              <FormErrors
                className={formErrorsAlert}
                show
                title={errorMessageLabel}
                // errors={[...errorMessage, errors.termsOfUse]}
                errors={errorMessage}
              />
            ) : null}
          </Box>
        </Box>
      </Box>
    </SignInFormLayout>
  );
};

export default ResetPasswordPage;
